<template>
<div class="inspect-index">
  <div class="title">{{ pageData.title }}</div>
  <div class="content">
    <div class="content-title">
      <div style="display:flex;height:100%;align-items: center;">
        <el-button style="margin-right: 50px;" @click="resetData" icon="el-icon-refresh" size="medium" :loading="loading" v-throttle>刷新</el-button>
        <el-form v-if="pageData.type == 'organ_list'" :inline="true" :model="formInline1" class="demo-form-inline">
          <el-form-item class="inline-form" label="机构编号">
            <el-input v-model="formInline1.organ_code" size="medium" placeholder="请输入机构编号"></el-input>
          </el-form-item>
          <el-form-item class="inline-form" label="机构名称">
            <el-input v-model="formInline1.name" size="medium" placeholder="请输入机构名称"></el-input>
          </el-form-item>
          <el-form-item class="inline-form">
            <el-button type="primary" size="medium" @click="search1">查询</el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="pageData.type == 'user_list'" :inline="true" :model="formInline2" class="demo-form-inline">
          <el-form-item class="inline-form" label="用户名">
            <el-input v-model="formInline2.username" size="medium" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item class="inline-form" label="所属机构">
            <el-input v-model="formInline2.organ_name" size="medium" placeholder="请输入所属机构"></el-input>
          </el-form-item>
          <el-form-item class="inline-form">
            <el-button type="primary" size="medium" @click="search2">查询</el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="pageData.type == 'admin_list'" :inline="true" :model="formInline3" class="demo-form-inline">
          <el-form-item class="inline-form" label="用户名">
            <el-input v-model="formInline3.username" size="medium" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item class="inline-form" label="单位">
            <el-input v-model="formInline3.work_name" size="medium" placeholder="请输入单位"></el-input>
          </el-form-item>
          <el-form-item class="inline-form">
            <el-button type="primary" size="medium" @click="search3">查询</el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="isTableActive && (role ==2 || role == 1)" :inline="true" :model="formInline3" class="demo-form-inline">
          <el-form-item class="inline-form" label="机构名称：" prop="organization">
            <el-select class="select" v-model="organization" filterable placeholder="请选择机构名称">
              <el-option v-for="item in organizationList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="inline-form" label="所属省市：" prop="organization">
            <el-select class="select" v-model="city" filterable placeholder="请选择省市">
              <el-option v-for="item in cityList" :key="item.city_code" :label="item.city_name" :value="item.city_code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="inline-form">
            <el-button type="primary" size="medium" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-button v-if="isInspect && !listCount" :loading="createLoading" @click="create" size="medium" type="primary" v-throttle>创建评估</el-button>
      <el-button v-if="pageData.type == 'organ_list'" :loading="createLoading" @click="jumpOrganization('add')" size="medium" type="primary" v-throttle>创建机构</el-button>
      <el-button v-if="pageData.type == 'user_list'" :loading="createLoading" @click="jumpUser('add')" size="medium" type="primary" v-throttle>添加用户</el-button>
      <el-button v-if="pageData.type == 'admin_list'" :loading="createLoading" @click="jumpAdmin('add')" size="medium" type="primary" v-throttle>添加用户</el-button>
    </div>
    <el-table v-loading="loading" class="table" stripe :data="tableData" style="width: 100%">
      <!-- 机构列表 -->
      <template v-if="pageData.type == 'organ_list'">
        <el-table-column key="organ_1" prop="organ_code" label="机构编号"></el-table-column>
        <el-table-column key="organ_2" prop="name" label="机构名称"></el-table-column>
        <el-table-column key="organ_3" prop="provice" label="所属省市区"></el-table-column>
        <el-table-column key="organ_4" label="机构状态">
          <template slot-scope="scope">
            <el-tag size="small" :type="(scope.row.status == 1) ? '' : 'info'" effect="plain">
              {{ (scope.row.status == 1) ? '开启' :'关闭'}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column key="organ_5" prop="created_at" label="创建时间"></el-table-column>
        <el-table-column key="organ_6" label="操作">
          <template slot-scope="scope">
            <el-button @click.native.prevent="jumpOrganization('check', scope.row.id)" type="text" v-throttle>详情</el-button>
            <span>｜</span>
            <el-button @click.native.prevent="jumpOrganization('edit', scope.row.id)" type="text" v-throttle>编辑</el-button>
            <span>｜</span>
            <el-button @click.native.prevent="deleteRow(scope.row.id)" type="text" v-throttle>删除</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 质量评审评审列表 -->
      <template v-if="isReview">
        <el-table-column key="supervision_31" prop="org_name" label="机构名称"></el-table-column>
        <el-table-column key="supervision_33" v-if="role != 4" prop="provice" label="所属省市区"></el-table-column>
        <el-table-column key="supervision_32" label="评估类型">
          <!-- !(role == 3 && pageData.type == 'school_review') -->
          <template slot-scope="scope">
            <p v-if="scope.row.type == 1">机构自评</p>
            <p v-if="scope.row.type == 2">区县他评</p>
            <p v-if="scope.row.type == 3">市级他评</p>
          </template>
        </el-table-column>
        <el-table-column key="supervision_23" prop="user_name" label="创建人"></el-table-column>
        <template>
          <el-table-column key="supervision_29" prop="examine_start_time" label="评估开始时间"></el-table-column>
          <el-table-column key="supervision_24" prop="examine_score" label="评审总分数"></el-table-column>
          <el-table-column key="supervision_25" label="评审进度">
            <template slot-scope="scope">
              <el-progress :percentage="scope.row.progress"></el-progress>
            </template>
          </el-table-column>
          <el-table-column key="supervision_26" prop="examine_stars">
            <template slot="header">
              标<img style="height: 16px;transform:translateY(2px)" src="../assets/index/star.png" alt="">通过项
            </template>
          </el-table-column>
          <el-table-column key="supervision_27" label="评审状态">
            <template>
              <p>评审中</p>
            </template>
          </el-table-column>
          <el-table-column key="supervision_28" label="操作">
            <template slot-scope="scope">
              <el-button v-if="(role == 2 || role == 1) && scope.row.type == 2" @click.native.prevent="editInspect(scope.row.inspect_id)" type="text" v-throttle>查看评审</el-button>
              <template v-else>
                <el-button @click.native.prevent="editInspect(scope.row.inspect_id)" type="text" v-throttle>开始评审</el-button>
                <span>｜</span>
                <el-button @click.native.prevent="eximainRelease(scope.row)" type="text" v-throttle>提交评审</el-button>
                <span v-if="scope.row.type != 1">｜</span>
                <el-button v-if="scope.row.type != 1" @click.native.prevent="deleteRow(scope.row.inspect_id)" type="text" v-throttle>删除</el-button>
              </template>
            </template>
          </el-table-column>
        </template>
      </template>
      <!-- 质量评审已评审列表 -->
      <template v-if="isReviewed && role != 4">
        <el-table-column key="supervision_31" prop="org_name" label="机构名称"></el-table-column>
        <el-table-column key="supervision_34" v-if="role != 4" prop="provice" label="所属省市区"></el-table-column>
        <el-table-column key="supervision_32" label="评估类型">
          <template slot-scope="scope">
            <p v-if="scope.row.type == 1">机构自评</p>
            <p v-if="scope.row.type == 2">区县他评</p>
            <p v-if="scope.row.type == 3">市级他评</p>
          </template>
        </el-table-column>
        <el-table-column key="supervision_23" prop="user_name" label="创建人"></el-table-column>
        <template>
          <el-table-column key="supervision_33" prop="examine_date" label="评审结束时间"></el-table-column>
          <el-table-column key="supervision_34" label="评审等级">
            <template slot-scope="scope">
              <p v-if="scope.row.examine_results == 1" style="color:#F04134">需要重大改进</p>
              <p v-else-if="scope.row.examine_results == 2" style="color:#FFBA01">接近国家标准</p>
              <p v-else-if="scope.row.examine_results == 3" style="color:#1ABE6B">符合国家标准</p>
              <p v-else-if="scope.row.examine_results == 4" style="color:#0079FE">超过国家标准</p>
              <p v-else style="color:#0079FE"></p>
            </template>
          </el-table-column>
          <el-table-column key="supervision_35" prop="examine_score" label="评审总分数"></el-table-column>
          <el-table-column key="supervision_36" prop="examine_stars">
            <template slot="header">
              标<img style="height: 16px;transform:translateY(2px)" src="../assets/index/star.png" alt="">通过项
            </template>
          </el-table-column>
          <el-table-column key="supervision_37" label="评审状态">
            <template>
              <p>已评审</p>
            </template>
          </el-table-column>
          <el-table-column key="supervision_38" prop="examine_name" label="评审人"></el-table-column>
          <el-table-column key="supervision_39" label="操作">
            <template slot-scope="scope">
              <el-button @click.native.prevent="editInspect(scope.row.inspect_id)" type="text" v-throttle>查看评估</el-button>
              <span>｜</span>
              <el-button @click.native.prevent="report(scope.row.inspect_id)" type="text" v-throttle>评估报告</el-button>
            </template>
          </el-table-column>
        </template>
      </template>
      <!-- 机构用户列表 -->
      <template v-if="pageData.type == 'user_list'">
        <el-table-column key="user_1" prop="id" label="ID"></el-table-column>
        <el-table-column key="user_2" prop="username" label="用户名"></el-table-column>
        <el-table-column key="user_3" prop="name" label="姓名"></el-table-column>
        <el-table-column key="user_3" prop="phone" label="手机号"></el-table-column>
        <el-table-column key="user_4" prop="organization_name" label="机构名称"></el-table-column>
        <el-table-column key="user_5" prop="role_name" label="角色"></el-table-column>
        <el-table-column key="user_6" label="状态">
          <template slot-scope="scope">
            <el-tag size="small" :type="(scope.row.status == 1) ? '' : 'info'" effect="plain">
              {{ (scope.row.status == 1) ? '开启' :'关闭'}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column key="user_7" prop="logined_at" label="最后登录时间"></el-table-column>
        <el-table-column key="user_8" label="操作">
          <template slot-scope="scope">
            <el-button @click.native.prevent="jumpUser('edit', scope.row.id)" type="text" v-throttle>编辑</el-button>
            <span>｜</span>
            <el-button @click.native.prevent="resetPassword(scope.row.id)" type="text" v-throttle>重置密码</el-button>
            <span>｜</span>
            <el-button @click.native.prevent="deleteRow(scope.row.id)" type="text" v-throttle>删除</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 机构用户列表 -->
      <template v-if="pageData.type == 'admin_list'">
        <el-table-column key="admin_1" prop="id" label="ID"></el-table-column>
        <el-table-column key="admin_2" prop="username" label="用户名"></el-table-column>
        <el-table-column key="admin_3" prop="name" label="姓名"></el-table-column>
        <el-table-column key="admin_5" prop="role_name" label="角色"></el-table-column>
        <el-table-column key="admin_9" prop="work_place" label="单位"></el-table-column>
        <el-table-column key="admin_6" label="状态">
          <template slot-scope="scope">
            <el-tag size="small" :type="(scope.row.status == 1) ? '' : 'info'" effect="plain">
              {{ (scope.row.status == 1) ? '开启' :'关闭'}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column key="admin_7" prop="logined_at" label="最后登录时间"></el-table-column>
        <el-table-column key="admin_8" label="操作">
          <template slot-scope="scope">
            <el-button @click.native.prevent="jumpAdmin('edit', scope.row.id)" type="text" v-throttle>编辑</el-button>
            <span>｜</span>
            <el-button @click.native.prevent="resetPassword(scope.row.id)" type="text" v-throttle>重置密码</el-button>
            <span>｜</span>
            <el-button @click.native.prevent="deleteRow(scope.row.id)" type="text" v-throttle>删除</el-button>
            <span v-if="scope.row.can_sendsms">｜</span>
            <el-button v-if="scope.row.can_sendsms" @click.native.prevent="sendsms(scope.row.username)" type="text" v-throttle>发送短信</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 机构评测列表1 -->
      <template v-if="pageData.type == 'school_not_submit'">
        <el-table-column key="not_submit_1" prop="org_name" label="机构名称"></el-table-column>
        <el-table-column key="not_submit_8" v-if="role != 4" prop="provice" label="所属省市区"></el-table-column>
        <el-table-column key="not_submit_2" prop="created_time" label="创建时间"></el-table-column>
        <el-table-column key="not_submit_3" prop="user_name" label="创建人"></el-table-column>
        <el-table-column key="not_submit_4" prop="score" label="自评总分数"></el-table-column>
        <el-table-column key="not_submit_5" label="自评进度">
          <template slot-scope="scope">
            <el-progress :percentage="scope.row.progress"></el-progress>
          </template>
        </el-table-column>
        <el-table-column key="not_submit_6" label="评估用时">
          <template slot-scope="scope">
            {{scope.row.total_time || '0时0分0秒'}}
          </template>
        </el-table-column>
        <el-table-column key="not_submit_7" label="操作">
          <template slot-scope="scope">
            <el-button @click.native.prevent="editInspect(scope.row.inspect_id)" type="text" v-throttle>继续自评</el-button>
            <span>｜</span>
            <el-button @click.native.prevent="schoolRelease(scope.row.inspect_id)" type="text" v-throttle>提交</el-button>
            <span>｜</span>
            <el-button @click.native.prevent="deleteRow(scope.row.inspect_id)" type="text" v-throttle>删除</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 机构评测列表2 -->
      <template v-if="pageData.type == 'school_submitted'">
        <el-table-column key="submitted_1" prop="org_name" label="机构名称"></el-table-column>
        <el-table-column key="submitted_7" v-if="role != 4" prop="provice" label="所属省市区"></el-table-column>
        <el-table-column key="submitted_2" prop="submit_date" label="提交时间"></el-table-column>
        <el-table-column key="submitted_3" prop="user_name" label="创建人"></el-table-column>
        <template>
          <el-table-column key="submitted_4" prop="score" label="自评总分数"></el-table-column>
          <el-table-column key="submitted_5" label="评估用时">
            <template slot-scope="scope">
              {{scope.row.total_time || '0时0分0秒'}}
            </template>
          </el-table-column>
          <el-table-column key="submitted_6" label="操作">
            <template slot-scope="scope">
              <el-button @click.native.prevent="editInspect(scope.row.inspect_id,'readonly')" type="text" v-throttle>查看评估</el-button>
              <span v-if="role !=4">｜</span>
              <el-button v-if="role !=4" @click.native.prevent="startExamine(scope.row.inspect_id)" type="text" v-throttle>开始评审</el-button>
            </template>
          </el-table-column>
        </template>
      </template>
      <!-- 机构评测列表3 -->
      <template v-if="isReviewed && role == 4">
        <el-table-column key="reviewed_1" prop="org_name" label="机构名称"></el-table-column>
        <el-table-column key="reviewed_8" v-if="role != 4" prop="provice" label="所属省市区"></el-table-column>
        <el-table-column key="reviewed_2" label="评估类型">
          <template slot-scope="scope">
            <p v-if="scope.row.type == 1">机构自评</p>
            <p v-if="scope.row.type == 2">区县他评</p>
            <p v-if="scope.row.type == 3">市级他评</p>
          </template>
        </el-table-column>
        <el-table-column key="reviewed_3" prop="created_time" label="提交时间"></el-table-column>
        <el-table-column key="reviewed_4" prop="user_name" label="创建人"></el-table-column>
        <template v-if="role != 3">
          <el-table-column key="reviewed_5" prop="score" label="自评总分数"></el-table-column>
          <el-table-column key="reviewed_6" prop="examine_date" label="评审时间">
          </el-table-column>
          <el-table-column key="reviewed_7" label="操作">
            <template slot-scope="scope">
              <el-button @click.native.prevent="editInspect(scope.row.inspect_id)" type="text" v-throttle>查看评估</el-button>
            </template>
          </el-table-column>
        </template>
      </template>
    </el-table>
    <el-pagination :background="true" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next" :total="listCount">
    </el-pagination>
  </div>
</div>
</template>

<script>
export default {
  name: "inspect-index",
  computed: {
    isTest() {
      return location.href.indexOf('.test.') != -1 || location.href.indexOf(':808') != -1
    },
    isInspect() {
      return this.pageData.type == "school_not_submit";
    },
    isTableActive() {
      let t = this.pageData.type;
      return t == "school_not_submit" || t == "school_submitted" || t == "school_reviewed" || t == "school_review" || t == "city_create" || t == "city_review" || t == "city_reviewed" || t == "country_create" || t == "country_review" || t == "country_reviewed";
    },
    isSchoolType() {
      let t = this.pageData.type;
      return t == "school_not_submit" || t == "school_submitted" || t == "school_reviewed" || t == "school_review";
    },
    isCityType() {
      let t = this.pageData.type;
      return t == "city_create" || t == "city_review" || t == "city_reviewed";
    },
    isCountryType() {
      let t = this.pageData.type;
      return t == "country_create" || t == "country_review" || t == "country_reviewed";
    },
    isReview() {
      let t = this.pageData.type;
      return t == 'school_review' || t == 'city_review' || t == 'country_review';
    },
    isReviewed() {
      let t = this.pageData.type;
      return t == 'school_reviewed' || t == 'city_reviewed' || t == 'country_reviewed';
    },
    isOrganType() {
      let t = this.pageData.type;
      return t == 'organ_list' || t == 'user_list';
    }
  },
  data() {
    return {
      organization: '',
      organizationList: [],
      city: '',
      cityList: [],
      pageData: {},
      pageList: [{
          type: "school_not_submit",
          title: "托育机构未提交列表",
        },
        {
          type: "school_submitted",
          title: "托育机构已提交列表",
        },
        {
          type: "school_review",
          title: "托育机构评审中列表",
        },
        {
          type: "school_reviewed",
          title: "托育机构已评审列表",
        },
        {
          type: "city_review",
          title: "区县评审中列表",
        },
        {
          type: "city_reviewed",
          title: "区县已评审列表",
        },
        {
          type: "country_review",
          title: "市级评审中列表",
        },
        {
          type: "country_reviewed",
          title: "市级已评审列表",
        },
        {
          type: "organ_list",
          title: "机构列表",
        },
        {
          type: "user_list",
          title: "机构用户列表",
        },
        {
          type: "admin_list",
          title: "管理员列表",
        },
      ],
      loading: false,
      createLoading: false,
      tableData: [],
      currentPage: 1,
      limit: 10,
      listCount: 0,
      object_all: 0,
      submitLoading: false,
      formInline1: {
        organ_code: '',
        name: '',
      },
      formInline2: {
        username: '',
        organ_name: ''
      },
      formInline3: {
        username: '',
        work_name: ''
      }
    };
  },
  async mounted() {
    this.pageData = this.pageList.filter((e) => {
      return e.type == this.$route.params.type;
    })[0];
    this.role = localStorage.getItem('role') * 1;
    this.init();
    let res1 = await this.$api({
      method: "get",
      url: "/api/inspect/organ/list",
      data: {
        page: 1,
        limit: 10000
      }
    });
    if (res1.code == 0) {
      this.organizationList = res1.data.list;
    }

    let res2 = await this.$api({
      method: "get",
      url: "/api/inspect/city/list",
      data: {
        page: 1,
        limit: 10000
      }
    });
    if (res2.code == 0) {
      this.cityList = res2.data.city_list;
    }
  },
  methods: {
    editInspect(inspect_id, readonly) {
      console.log(inspect_id)
      this.$router.push({
        path: "/inspect",
        query: {
          inspect_id,
          readonly
        }
      });
    },
    report(inspect_id) {
      this.$router.push({
        path: "/report",
        query: {
          inspect_id,
        }
      })
    },
    async search1() {
      this.loading = true;
      this.currentpage = 1;
      let res = await this.$api({
        method: "get",
        url: "/api/organ/list",
        data: {
          page: this.currentPage,
          limit: this.limit,
          organ_code: this.formInline1.organ_code,
          name: this.formInline1.name
        }
      });
      this.loading = false;
      if (res.code == 0) {
        this.listCount = res.data.num_all;
        this.tableData = res.data.list.map(e => {
          e.provice = e.province_name + '-' + e.city_name + '-' + e.area_name;
          return e;
        })
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async search2() {
      this.loading = true;
      this.currentpage = 1;
      let res = await this.$api({
        method: "get",
        url: "/api/organ/userlist",
        data: {
          page: this.currentPage,
          limit: this.limit,
          organ_name: this.formInline2.organ_name,
          username: this.formInline2.username
        }
      });
      this.loading = false;
      if (res.code == 0) {
        this.listCount = res.data.num_all;
        this.tableData = res.data.list.map(e => {
          return e;
        })
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async search3() {
      this.loading = true;
      this.currentpage = 1;
      let res = await this.$api({
        method: "get",
        url: "/api/organ/adminlist",
        data: {
          page: this.currentPage,
          limit: this.limit,
          work_name: this.formInline3.work_name,
          username: this.formInline3.username
        }
      });
      this.loading = false;
      if (res.code == 0) {
        this.listCount = res.data.num_all;
        this.tableData = res.data.list.map(e => {
          return e;
        })
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async init() {
      this.loading = true;
      console.log('getlist', this.isTableActive, this.isReview, this.isCityType)
      if (this.isTableActive) {
        let type = 1;
        if (this.pageData.type == 'school_submitted') {
          type = 2;
        } else if (this.isReviewed) {
          type = 3;
        } else if (this.isReview) {
          type = 4;
        }
        let inspect_type = 1;
        if (this.isSchoolType) {
          inspect_type = 1;
        } else if (this.isCityType) {
          inspect_type = 2;
        } else if (this.isCountryType) {
          inspect_type = 3;
        }
        let res = await this.$api({
          method: "get",
          url: "/api/inspect/index",
          data: {
            page: this.currentPage,
            limit: this.limit,
            type,
            inspect_type,
            city_code: this.city,
            organization_id: this.organization
          }
        });
        this.loading = false;
        if (res.code == 0) {
          console.log(res.data)
          this.listCount = res.data.num_all || 0;
          this.tableData = res.data.list.map(e => {
            e.provice = e.province_name + '-' + e.city_name + '-' + e.area_name;
            if (e.type != 1) {
              // 不是机构来的
              e.examine_score = e.score * 1 + '分';
              e.progress = e.ratio.replace('%', '') * 1;
              e.examine_stars = e.stars;
              e.examine_total_object = e.total_object;
              e.examine_results = e.results;
            } else {
              // 机构来的
              if (this.role == 4) {
                e.examine_score = e.examine_score * 1 + '分';
                e.score = e.score * 1 + '分'
                e.progress = (e.ratio.replace('%', '')) * 1;
              } else {
                e.examine_score = e.examine_score * 1 + '分';
                e.score = e.score * 1 + '分'
                e.progress = (e.examine_ratio.replace('%', '')) * 1;
              }
            }

            return e;
          })
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      } else if (this.pageData.type == 'organ_list') {
        let res = await this.$api({
          method: "get",
          url: "/api/organ/list",
          data: {
            page: this.currentPage,
            limit: this.limit
          }
        });
        this.loading = false;
        if (res.code == 0) {
          this.listCount = res.data.num_all;
          this.tableData = res.data.list.map(e => {
            e.provice = e.province_name + '-' + e.city_name + '-' + e.area_name;
            return e;
          })
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      } else if (this.pageData.type == 'user_list') {
        let res = await this.$api({
          method: "get",
          url: "/api/organ/userlist",
          data: {
            page: this.currentPage,
            limit: this.limit
          }
        });
        this.loading = false;
        if (res.code == 0) {
          this.listCount = res.data.num_all;
          this.tableData = res.data.list.map(e => {
            return e;
          })
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      } else if (this.pageData.type == 'admin_list') {
        let res = await this.$api({
          method: "get",
          url: "/api/organ/adminlist",
          data: {
            page: this.currentPage,
            limit: this.limit
          }
        });
        this.loading = false;
        if (res.code == 0) {
          this.listCount = res.data.num_all;
          this.tableData = res.data.list.map(e => {
            return e;
          })
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      } else {
        this.loading = false;
      }
    },
    supervisionChange(e) {
      localStorage.setItem('supervisionChange', e);
      this.init();
    },
    async schoolRelease(id) {
      if (this.submitLoading) {
        return;
      }
      this.submitLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/release",
        data: {
          inspect_id: id,
          get_number: 1
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.$confirm(`提交后不可修改，确定提交吗`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {
            this.releaseApi(id);
          })
          .catch(() => {});
      } else if (res.code == 1) {
        if (this.isTest) {
          this.$confirm(`提交后不可修改，确定提交吗`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              center: true,
              customClass: "customConfirm",
              confirmButtonClass: "confirmButtonClass",
              cancelButtonClass: "cancelButtonClass",
            })
            .then(() => {
              this.releaseApi(id);
            })
            .catch(() => {});
          return
        }
        this.$confirm(`有${res.data.not_number}项评估遗漏，无法提交。`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "继续评估",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {
            // this.releaseApi(id);
          })
          .catch(() => {
            this.editInspect(id)
          });
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }

    },
    async eximainRelease(item) {
      if (this.isTest) {
        item.object_all = 0;
        item.examine_total_object = 0;
      }
      if (item.object_all - item.examine_total_object > 0) {
        this.$confirm(`有${item.object_all - item.examine_total_object}项没有评估，无法提交。`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "继续评估",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {})
          .catch(() => {
            this.editInspect(item.inspect_id)
          });
      } else {
        this.$confirm(`提交后不可修改，确定提交吗`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {
            this.releaseApi(item.inspect_id);
          })
          .catch(() => {});
      }
    },
    async releaseApi(id) {
      if (this.submitLoading) {
        return;
      }
      this.submitLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/release",
        data: {
          inspect_id: id,
          get_number: 0
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.$message({
          message: '提交成功',
          type: 'success'
        });
        if (this.pageData.type == 'school_not_submit') {
          this.$router.push('/table/school_submitted');
        } else if (this.pageData.type == 'school_review') {
          this.$router.push('/table/school_reviewed');
        } else if (this.pageData.type == 'city_review') {
          this.$router.push('/table/city_reviewed');
        } else if (this.pageData.type == 'country_review') {
          this.$router.push('/table/country_reviewed');
        }
      } else if (res.code == -2) {
        this.$confirm(`您尚未填写问卷调研，填写后方可提交。`, "问卷调查", {
            confirmButtonText: "去填写",
            cancelButtonText: "取消",
            type: "error",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {
            this.$router.push('/questionnaire?inspect_id=' + id)
          })
          .catch(() => {});
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async startExamine(id) {
      if (this.submitLoading) {
        return;
      }
      this.submitLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/start_examine",
        data: {
          inspect_id: id,
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.editInspect(id)
        sessionStorage.setItem('backPage', '/table/school_review')
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    resetPassword(id) {
      this.$confirm("此操作将重置用户密码, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
          customClass: "customConfirm",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonClass: "cancelButtonClass",
        })
        .then(async () => {
          if (this.submitLoading) {
            return;
          }
          this.submitLoading = true;
          let url = '';
          if (this.pageData.type == 'user_list') {
            url = "/api/organ/userresetpwd";
          } else if (this.pageData.type == 'admin_list') {
            url = "/api/organ/adminresetpwd";
          }
          let res = await this.$api({
            method: "post",
            url: url,
            data: {
              id: id
            }
          });
          this.submitLoading = false;
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "重置密码成功!",
            });
            this.resetData();
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .catch(() => {

        });
    },
    sendsms(name) {
      this.$confirm("将会给用户发送短信, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
          customClass: "customConfirm",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonClass: "cancelButtonClass",
        })
        .then(async () => {
          let res = await this.$api({
            method: "post",
            url: '/api/sendsms',
            data: {
              mobile: name
            }
          });
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "发送成功!",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        }).catch(() => {

        });
    },
    deleteRow(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
          customClass: "customConfirm",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonClass: "cancelButtonClass",
        })
        .then(async () => {
          let url = '';
          let data = {};
          if (this.pageData.type == 'organ_list') {
            url = "/api/organ/delete";
            data = {
              id: id
            }
          } else if (this.pageData.type == 'user_list') {
            url = "/api/organ/userdelete"
            data = {
              id: id
            }
          } else if (this.pageData.type == 'school_not_submit') {
            url = "/api/inspect/delete"
            data = {
              inspect_id: id
            }
          } else if (this.pageData.type == 'admin_list') {
            url = "/api/organ/admindelete"
            data = {
              id: id
            }
          } else if (this.pageData.type == 'city_review') {
            url = "/api/inspect/delete"
            data = {
              inspect_id: id
            }
          } else if (this.pageData.type == 'country_review') {
            url = "/api/inspect/delete"
            data = {
              inspect_id: id
            }
          }

          if (this.submitLoading) {
            return;
          }
          this.submitLoading = true;
          let res = await this.$api({
            method: "post",
            url: url,
            data: data
          });
          this.submitLoading = false;
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.resetData();
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .catch(() => {

        });
    },
    resetData() {
      this.currentPage = 1;
      this.formInline1 = {
        organ_code: '',
        name: '',
      };
      this.formInline2 = {
        username: '',
        organ_name: ''
      };
      this.formInline3 = {
        username: '',
        work_name: ''
      };
      this.city = '';
      this.organization = '';
      this.init();
    },
    search() {
      this.currentPage = 1;
      this.init();
    },
    adminCreate() {
      this.$router.push('/inspect/create')
    },
    async create() {
      this.createLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/create",
      });
      console.log(res);
      if (res.code == 0) {
        this.$message({
          message: '创建成功',
          type: 'success'
        });
        this.$router.push({
          path: "/inspect",
          query: {
            inspect_id: res.data.inspect_id
          }
        });
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
      this.createLoading = false;

    },
    jumpOrganization(type, id) {
      console.log(id)
      if (type == 'add') {
        this.createLoading = true;
      }
      this.$router.push({
        path: "/organization/" + type,
        query: {
          id: id
        }
      });
      this.createLoading = false;

    },
    jumpUser(type, id) {
      this.createLoading = true;
      setTimeout(() => {
        this.createLoading = false;
        this.$router.push({
          path: "/organization/user/" + type,
          query: {
            id
          },
        });
      }, 500);
    },
    jumpAdmin(type, id) {
      this.createLoading = true;
      setTimeout(() => {
        this.createLoading = false;
        this.$router.push({
          path: "/organization/admin/" + type,
          query: {
            id
          },
        });
      }, 500);
    },
    handleCurrentChange(index) {
      this.init();
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate')
    this.pageData = this.pageList.filter((e) => {
      return e.type == to.params.type;
    })[0];
    this.resetData();
    next();
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.inspect-index {
  padding: 10px 20px;
  min-height: calc(100vh - 150px);

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }

  .content {
    overflow: hidden;
    width: 100%;
    background: #fff;
    margin-top: 20px;
    padding: 0 2vw;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;

    .content-title {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .inline-form {
      margin-bottom: 0;
      margin-right: 40px;
    }

    .table {
      margin-bottom: 50px;
      height: 100%;
    }
  }
}
</style>

<style lang="scss">
.inspect-index {
  .el-pagination {
    margin-bottom: 40px;
    text-align: right;
    position: relative;

    .el-pagination__total {
      position: absolute;
      left: 0;
    }
  }

  .el-table::before {
    height: 0;
  }
}
</style>
